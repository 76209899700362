
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Auth = namespace("auth");

@Component({
  components: { ValidationProvider, ValidationObserver }
})
export default class LoginPage extends Vue {
  email = "";
  password = "";
  remember = true;
  active = true;

  @Auth.Getter("getIsCorrect")
  public getIsCorrect!: boolean;

  @Auth.Action("loginRequest")
  public loginRequest!: ({
    email,
    password
  }: {
    email: string;
    password: string;
  }) => Promise<void>;

  async login(): Promise<void> {
    try {
      await this.loginRequest({
        email: this.email,
        password: this.password
      });
      await this.$router.push({ name: "CourseEvents" });
    } catch (e) {
      (this.$refs.loginForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(e.errors);
      await this.$error(e.errors);
    }
  }
}
